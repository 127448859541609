<template>
  <div>
    <v-container
        v-if="pending_payments"
    >
      <v-row
          justify="center"
      >
        <v-col
            cols="12"
            md="12"
        >
          <v-row>
            <v-col
                cols="12"
                md="12"
            >
              <v-card-title>
                <div>
                  <v-breadcrumbs :items="links" />
                </div>
                <v-spacer />
                <div
                    class="text-h3 text-center font-weight-medium"
                >
                  Upload a new bookings
                </div>
                <v-spacer />
                <v-spacer />
              </v-card-title>
            </v-col>
          </v-row>
          <v-container
              class="rounded elevation-0 pt-16 pb-16"
          >
            <v-alert
                :value="alert"
                type="danger"
                border="left"
                elevation="2"
                colored-border
                icon="mdi-check-circle-outline">
              Kindly settle your account payments to continue using this service, OR contact your account manager.
            </v-alert>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-container
        v-else-if="!isMakerChecker"
        id="user-profile"
        fluid
        tag="section"
    >
      <v-row
          justify="center"
      >
        <v-col
            cols="12"
            md="12"
        >
          <v-row>
            <v-col
                cols="12"
                md="12"
            >
              <v-card-title>
                <div>
                  <v-breadcrumbs :items="links" />
                </div>
                <v-spacer />
                <div
                    class="text-h3 text-center font-weight-medium"
                >
                  New Individual booking
                </div>
                <v-spacer />
                <v-spacer />
              </v-card-title>
            </v-col>
          </v-row>
          <v-container
              class="rounded elevation-0"
          >
            <v-form
                v-model="valid"
                class="rounded elevation-2 pa-3"
                @submit.prevent="UploadFile"
            >
              <v-row class="mt-2">
                <v-col
                    cols="4"
                    md="4"
                >
                  <v-select
                      v-model="deductionCode"
                      :items="dasCodes"
                      item-text="deductionCode"
                      item-value="deductionCode"
                      label="Select Deduction Code"
                      outlined
                      :rules="[rules.required]"
                      style="text-transform: uppercase"
                      @change="getPayeeCodes()"
                  />
                </v-col>
                <v-col
                    cols="4"
                    md="4"
                >
                  <v-text-field
                      v-model="dasCurrency"
                      outlined
                      label="Currency"
                      class="purple-input my-input"
                      autocomplete="off"
                      disabled="true"
                      :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                    cols="4"
                    md="4"
                >
                  <v-select
                      v-model="payeeCode"
                      :items="payeeCodes"
                      item-text="payeeCode"
                      item-value="payeeCode"
                      label="Select Payee Code"
                      outlined
                      :rules="[rules.required]"
                      style="text-transform: uppercase"
                  />
                  <v-text-field
                      v-if="false"
                      v-model="payeeCode"
                      outlined
                      label="Payee Code"
                      class="purple-input my-input"
                      autocomplete="off"
                      disabled="true"
                      :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                    cols="4"
                    md="6"
                >
                  <v-text-field
                      v-model="ecNumber"
                      outlined
                      label="EC Number"
                      class="purple-input my-input"
                      autocomplete="off"
                      :rules="[rules.required, rules.ecNumber, rules.EcCount]"
                  />
                </v-col>
                <v-col
                    cols="4"
                    md="6"
                >
                  <v-text-field
                      v-model="idNumber"
                      outlined
                      label="ID Number"
                      class="purple-input my-input"
                      autocomplete="off"
                      :rules="[rules.required, rules.idNumber, rules.idMaxLength, rules.idMinLength]"
                  />
                </v-col>
                <v-col
                    cols="4"
                    md="6"
                >
                  <v-text-field
                      v-model="accountID"
                      outlined
                      label="Reference"
                      class="purple-input my-input"
                      autocomplete="off"
                      :rules="$store.getters.institution==='MICROPLAN' ? [rules.required] : [rules.required, rules.reference]"
                  />
                </v-col>
                <v-col
                    cols="4"
                    md="4"
                >
                  <v-text-field
                      v-model="amountCents"
                      outlined
                      label="Amount in ( cents )"
                      class="purple-input my-input"
                      autocomplete="off"
                      :rules="[rules.required, rules.isnumber]"
                      @input="CalculateCents"
                  />
                </v-col>
                <v-col
                    cols="4"
                    md="2"
                >
                  <v-text-field
                      v-model="amount"
                      outlined
                      label="Amount"
                      class="purple-input my-input"
                      autocomplete="off"
                      disabled="true"
                      :prefix= dasCurrency
                      :rules="[rules.required]"
                  />
                </v-col>
                <v-col
                    cols="4"
                    md="4"
                >
                  <v-select
                      v-model="type"
                      :items="types"
                      item-text="name"
                      item-value="value"
                      label="Select Booking Type"
                      outlined
                      :rules="[rules.required]"
                      style="text-transform: uppercase"
                  />
                </v-col>
                <v-col
                    cols="12"
                    sm="4"
                    md="3"
                >
                  <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="start_date"
                          label="Start date"
                          prepend-icon="mdi-calendar"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="start_date"
                        @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                    cols="12"
                    sm="4"
                    md="3"
                >
                  <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="end_date"
                          label="End date"
                          prepend-icon="mdi-calendar"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!EndDateState"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="end_date"
                        @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                    cols="12"
                    sm="3"
                    md="2"
                >
                  <v-switch
                      v-model="EndDateState"
                      inset
                      :label="`End Date ${EndStateText(EndDateState)}`"
                  ></v-switch>
                </v-col>
                <v-col
                    cols="12"
                    class="text-center mb-1"
                >
                  <v-btn
                      color="primary"
                      :disabled="!valid"
                      @click="SubmitBooking"
                  >
                    Submit Booking
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-col>
      </v-row>
      <v-dialog
          v-model="successUpload"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Upload Status
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #4caf50"
            >
              Upload Successful
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/success.svg"
                        width="8em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="goTo()"
                  >
                    Done
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="errorUpload"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Upload Status
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #800808"
            >
              Records already exist
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/error.svg"
                        width="8em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="goTo()"
                  >
                    Resubmit
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
    <v-container
        v-else
    >
      <v-row
          justify="center"
      >
        <v-col
            cols="12"
            md="12"
        >
          <v-row>
            <v-col
                cols="12"
                md="12"
            >
              <v-card-title>
                <div>
                  <v-breadcrumbs :items="links" />
                </div>
                <v-spacer />
                <div
                    class="text-h3 text-center font-weight-medium"
                >
                  Upload a new bookings
                </div>
                <v-spacer />
                <v-spacer />
              </v-card-title>
            </v-col>
          </v-row>
          <v-container
              class="rounded elevation-0 pt-16 pb-16"
          >
            <v-alert
                :value="alert"
                type="warning"
                border="left"
                elevation="2"
                colored-border
                icon="mdi-check-circle-outline">
              You have no permission to perform this action
            </v-alert>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular
            indeterminate
            size="64"
        />
      </v-overlay>
    </div>
  </div>
</template>
<script>
// import { mapState } from 'vuex'
import axios from 'axios'
import { API } from '../../../api'
import moment from 'moment'
import dataToJson from "data-to-json";
import * as XLSX from "xlsx";
import {mapState} from "vuex";
export default {
  name: 'Booking',
  data () {
    return {
      pending_payments: false,
      dasCodes: [],
      payeeCodes:[],
      isMakerChecker: false,
      EndDateState: true,
      start_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      end_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu2: false,
      menu1: false,
      overlay: false,
      valid:false,
      amountCents: '',
      amount: '',
      ecNumber: '',
      idNumber: '',
      accountID: '',
      successUpload:false,
      errorUpload: false,
      dasCurrency: '',
      deductionCode: '',
      payeeCode: '',
      uploadData:[],
      types: [
        { name: 'NEW', value: 'N' },
        { name: 'CHANGE', value: 'C' },
        { name: 'DELETE', value: 'D' }
      ],
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length >= 10 || 'Max 10 characters',
        reference: value => value.length <= 12 || 'Max 12 characters',
        idMaxLength: value => value.length <= 12 || 'Max ID length is 12 characters',
        idMinLength: value => value.length >= 11 || 'Min ID length is 11 characters',
        isnumber: value => isNaN(value) == false || 'Enter a valid number',
        ecNumber: value =>{
          const pattern = /[0-9]+[A-Za-z]/i
          return pattern.test(value) || 'Invalid ecNumber.'
        },
        EcCount: value => value.length <= 8 || 'Min 8 characters',
        counter_id: value => value.length >= 11 || 'Max 11 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        idNumber: value =>{
          const pattern = /^[0-9]+[A-Za-z]\d\d$/i;
          return pattern.test(value) || 'Invalid ID number'
        }
      },
      links: [
        {
          text: 'SSB',
          disabled: false,
          href: 'generate',
        },
        {
          text: 'Individual booking',
          disabled: true,
          href: '',
        },
      ],
    }
  },
  computed: {
    ...mapState(['maker_checker']),
  },
  created () {
    if(!this.$store.getters.invoice_paid){
      this.pending_payments = true
    }
    if (this.$store.getters.maker_checker && this.$store.getters.user_type === 'APPROVER') {
      this.isMakerChecker = true
    }
    else {
      this.isMakerChecker = false
      this.getDasCodes()
    }
  },
  methods: {
    async getDasCodes() {
      const JWTToken = this.$store.getters.accessToken
      axios.get('/das/payeeCode_and_currency/?organization_id=' + this.$store.getters.OrgId,
          { headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              this.dasCodes = res.data
              console.log(this.dasCodes)
            } else if (res.status === 202) {
              console.log('202')
            } else {
              console.log('error')
            }
          })
    },
    EndStateText(val) {
      let DisplayText = "Enabled";
      if (val){
        DisplayText = "Enabled";
      }else{
        DisplayText = "Disabled";
      }
      return DisplayText
    },
    async CalculateCents() {
      this.amount = this.amountCents / 100
      console.log(this.currency + this.amount)
    },
    async getPayeeCodes() {
      const dascode = this.dasCodes.find(item => item.deductionCode === this.deductionCode)
      this.dasCurrency = dascode.currency
      this.payeeCodes = this.dasCodes.filter(item => item.deductionCode === this.deductionCode)
      console.log(this.payeeCodes)
    },
    async populateDasCodes() {
      const dascode = this.dasCodes.find(item => item.deductionCode === this.deductionCode)
      this.payeeCode = dascode.payeeCode
      this.dasCurrency = dascode.currency
    },
    async SubmitBooking(){
      this.overlay = true
      let Todaydate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      console.log(Todaydate)
      console.log(this.end_date)
      let Enddate;
      if(this.EndDateState){
        Enddate = this.end_date
        Enddate = moment(Enddate).format('DD/MMM/YYYY');
      } else {
        Enddate = '        '
      }
      this.start_date = moment(this.start_date).format('DD/MMM/YYYY');
      this.uploadData =[
          {
            "accountId": this.accountID,
            "reference": this.accountID,
            "idNumber": this.idNumber,
            "ecNumber": this.ecNumber,
            "type": this.type,
            "startDate": this.start_date,
            "endDate": Enddate,
            "amount": this.amountCents,
          }
      ]
      console.log(this.$store.getters.user_type)
      console.log(this.$store.getters.maker_checker)
      if(this.$store.getters.user_type === 'INITIATOR' && this.$store.getters.maker_checker === true){
        this.uploadData.forEach((node) => node.status = 'PendingApproval');
      }
      else{
        this.uploadData.forEach((node) => node.status = 'Pending');
      }
      this.uploadData.forEach((node) => node.currency = this.dasCurrency);
      console.log(this.uploadData)
      await new Promise((resolve) => setTimeout(resolve, 3000));
      const JWTToken = this.$store.getters.accessToken
      await axios
          .post("/das/post_bookings/?payeeCode=" + this.payeeCode + "&deductionCode=" + this.deductionCode + "&organization=" + this.$store.getters.institution, this.uploadData
      ,{ headers: { Authorization: `Bearer ${JWTToken}` } })
          .then((response) => {
            console.log(response)
            this.overlay = true;
            if (response.status === 201) {
              console.log(response.data);
              this.overlay = false;
              this.successUpload = true;
              // this.$router.push("/user/dashboard").catch(() => {})
            } else if (response.status === 404){
              this.overlay = false;
            }
            else {
              this.overlay = false;
              this.errorUpload = true;
              console.log(response)
            }
          })
          .catch((error) => {
            if (error.response.status === 400) {
              this.overlay = false;
              this.errorUpload = true;
            } else if (error.response.status === 404) {
              this.overlay = false;
              this.errorUpload = true;
            } else if (error.response.status === 500) {
              this.overlay = false;
              this.errorUpload = true;
            }else{
              this.overlay = false;
              this.errorUpload = true;
            }
          });

    },
    GenerateRef(val){
      val = val.substring(1);
      val = val.slice(0, -2);
      return val;
    },
    GenerateRefID(){
      let randomNum = Math.floor((Math.random() * 800) + 111)
      const current =  moment().format("YYMMDDmmS");
      const RefID =  current + randomNum;
      return RefID;
    },
    GenerateBatchID(val){
      const Org = this.$store.getters.institution.substring(0, 3).toUpperCase();
      const TodayDate = moment().format("YYMMDDms");
      const BatchID = Org + TodayDate + val.length
      return BatchID;
    },

    goTo(){
      this.$router.push('/user/batch')
    },
    clear () {
      this.trans_summary = false
      this.$router.push('/user/dashboard')
    },
  },
}
</script>
<style>
.my-input input{
  text-transform: uppercase
}
</style>
