<template>
  <div>
    <v-container
        v-if="true"
        id="user-profile"
        fluid
        tag="section"
    >
      <v-row
          justify="center"
      >
        <v-col
            cols="12"
            md="12"
        >
          <v-row>
            <v-col
                cols="12"
                md="12"
            >
              <v-card-title>
                <div>
                  <v-breadcrumbs :items="links" />
                </div>
                <v-spacer />
                <div
                    class="text-h3 text-center font-weight-medium"
                >
                  Invoices
                </div>
                <v-spacer />
                <v-spacer />
              </v-card-title>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="1" md="1" style="justify-content: center">

            </v-col>
            <v-col  cols="10" md="10" style="background-color: whitesmoke">

              <v-card-title>
                Invoices
                <v-spacer></v-spacer>
                <template>
                  <v-btn
                      v-if="false"
                      class="mx-2"
                      fab
                      dark
                      small
                      color="primary"
                      @click="createExcel()"
                  >
                    <v-icon dark>
                      mdi-download
                    </v-icon>
                  </v-btn>
                </template>
                <v-spacer></v-spacer>
                <br>
                <br>
                <v-menu
                    v-if="false"
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="date"
                        label="Select Invoice Period"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>

                  </template>

                  <v-date-picker
                      v-model="date"
                      type="month"
                      no-title
                      scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="secondary"
                        @click="OnDateChange"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                  :headers="headers"
                  :items="batch"
                  :search="search"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
              >
                <template v-slot:item.Month="{ item }">
                  <span>{{ formatDate(item.Month) }}</span>
                </template>

                <template v-slot:item.Currency="{ item }">
                  <span>{{ item.Currency }}</span>
                </template>
                <template v-slot:item.invoice_amount="{ item }">
                  <span>{{ formatNumber(item.invoice_amount) }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn
                      color="primary"
                      dark
                      small
                      class="m-2"
                      @click="GoTo(item)"
                  >
                    <v-icon left>
                      mdi-file
                    </v-icon>
                    PDF
                  </v-btn>
                </template>
                <template v-slot:item.pop="{ item }">
                  <v-btn
                      color="primary"
                      dark
                      small
                      class="m-2"
                      @click="InvokePOPUpload(item)"
                  >
                    <v-icon left>
                      mdi-upload
                    </v-icon>
                    POP
                  </v-btn>
                </template>
                <template v-slot:item.pending_balance="{ item }">
                  <span>{{ item.pending_balance > 0 ? formatNumber(item.pending_balance) : '$0.00'}} </span>
                </template>
                <template v-slot:item.invoice_status="{ item }">
                  <v-chip
                      v-if="item.invoice_status == 'PAID'"
                      color="success"
                      dark
                  >
                    {{ item.invoice_status }}
                  </v-chip>
                  <v-chip
                      v-if="item.invoice_status == 'POP SUBMITTED'"
                      color="warning"
                      dark
                  >
                    {{ item.invoice_status }}
                  </v-chip>
                  <v-chip
                      v-if="item.invoice_status == 'PENDING'"
                      color="error"
                      dark
                  >
                    {{ item.invoice_status }}
                  </v-chip>
                  <v-chip
                      v-if="item.invoice_status == 'PARTIAL PAYMENT'"
                      color="purple"
                      dark
                  >
                    {{ item.invoice_status }}
                  </v-chip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <br><br><br>
        </v-col>
      </v-row>
      <v-dialog
          v-model="successDialog"
          persistent
          width="400"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Upload Status
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #045e1a !important"
            >
              Success POP submitted
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/success.svg"
                        width="5em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="clear"
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="errorDialog"
          persistent
          width="400"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Upload Response
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center ma-2">
            <br>
            <h5
                style="color: #790815 !important"
            >
              Error submitting POP , please check your file type and try again.
            </h5>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/error.svg"
                        width="3em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="clear"
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="uploadPOPDialog"
          persistent
          width="30%"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-btn
                icon
                dark
                @click="clear()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="text-center">
              Upload Proof of Payment
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2>
              Select Files to upload
            </h2>
            <v-form
                v-model="valid"
                @submit.prevent="uploadFile"
            >
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >

                  <v-file-input
                      v-model="files"
                      accept="image/*, application/pdf"
                      placeholder="Upload your documents"
                      label="File input"
                      multiple
                      prepend-icon="mdi-paperclip"
                      :rules="[rules.required]"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip
                          small
                          label
                          color="primary"
                      >
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      :disabled="!valid"
                      @click="uploadFile()"
                  >
                    Upload
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
            </v-form>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular
            indeterminate
            size="64"
        />
      </v-overlay>
    </div>
  </div>
</template>
<script>
// import { mapState } from 'vuex'
import axios from 'axios'
import { API } from '../../../api'
import moment from 'moment'
import dataToJson from "data-to-json";
import * as XLSX from "xlsx";
import xlsx from "xlsx";
export default {
  name: 'Batch',
  data () {
    return {
      batch: [],
      files: null,
      search: '',
      selectedData: {},
      overlay: false,
      uploadPOPDialog: false,
      comment: '',
      valid:false,
      successDialog: false,
      errorDialog: false,
      sortBy: 'month',
      sortDesc: true,
      headers: [
        { text: 'Month', value: 'month' },
        { text: 'Status', value: 'invoice_status', align: 'center' },
        // { text: 'Remaining Balance', value: 'pending_balance', align: 'center' },
        { text: 'Invoice View', value: 'actions', align: 'center' },
        { text: 'Upload POP', value: 'pop', align: 'center' },
      ],
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length >= 10 || 'Max 10 characters',
        counter_id: value => value.length >= 11 || 'Max 11 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      links: [
        {
          text: 'SSB',
          disabled: false,
          href: 'generate',
        },
        {
          text: 'Invoice',
          disabled: true,
          href: '',
        },
      ],
    }
  },
  created () {
    this.getInvoices()
  },
  methods: {
    async getInvoices() {
      this.overlay = true
      const JWTToken = this.$store.getters.accessToken
      axios.get('/das/billing/get-invoices/',{ headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              this.batch = res.data
              console.log(this.batch)
              this.overlay = false
            } else if (res.status === 404) {
              this.overlay = false
            } else {
              this.overlay = false
            }
          })
    },
    clear () {
      this.files = null
      this.overlay = false
      this.successDialog = false
      this.errorDialog = false
      this.uploadPOPDialog = false
      this.getBatch()
    },
    GoTo(val){
      let route = this.$router.resolve({path: '/invoice/' + val.month});
      window.open(route.href, '_blank');
      // this.$router.push('/invoice/' + val.month) // navigate to /user/batch/123')
    },
    InvokePOPUpload(val){
      this.uploadPOPDialog = true
      this.selectedData = val
    },
    formatDate(val){
      val.replace(/\s/g, '');
      let finalDate = moment(val).format('MMM YYYY')
      return finalDate;
    },
    // format number to US dollar
    formatNumber(val){
      let finalNumber = val.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
      return finalNumber;
    },

    async uploadFile() {
      this.uploadPOPDialog = false
      console.log(this.selectedData)
      this.overlay = true
      let file = this.files[0];
      if (file) {
        let extension = file.name.split('.').pop();
        console.log(extension)
        if (extension !== 'pdf' && extension !== 'jpg' && extension !== 'jpeg' && extension !== 'png') {
          this.overlay = false
          this.errorDialog = true
          return
        }
        const JWTToken = this.$store.getters.accessToken
        let formData = new FormData();
        formData.append(this.selectedData.month + ' - ' + this.selectedData.organisation_name , file);
        const config = {
          method: "post",
          url: "/das/billing/add-documents/" + this.selectedData.organisation_name + '/' + this.selectedData.month,
          headers: {
            // Authorization: `Bearer ${JWTToken}`,
            //'Content-Type': 'application/json',
            'Content-Type': 'multipart/form-data'
          },
          data: formData
        };
        axios(config)
            .then((response) => {
              if (response.status == 201) {
                this.getInvoices()
                this.overlay = false
                this.successDialog = true
              } else {
                console.log(response)
                this.errorDialog = true
                this.overlay = false
              }
              this.overlay = false
              console.log(response)
            }).catch((error) => {
          console.log(error)
        });
      } else {
        this.overlay = false
        this.errorDialog = true
      }

    },
  },
}
</script>
<style>
.my-input input{
  text-transform: uppercase
}
</style>
